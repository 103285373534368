import React from 'react';

function App() {
  return (
    <div className="App">
      <section className="hero-without-image wf-section">
        <div className="container">
          <div className="hero-wrapper-two"><h1>Corona Schnelltests in München</h1></div>
        </div>
      </section>
      <section className="logos-without-title wf-section">
        <div className="container"></div>
        <p className="margin-bottom-24px">Das Angebot Corona Schnelltests wurde eingestellt. Hier finden Sie unsere
          wichtigsten Rubriken aus dem Bereich Ärzte, Gesundheit &amp; Wellness:</p></section>
      <div className="w-layout-grid grid">
          <a href="https://www.muenchen.de/service/branchenbuch/Z/438.html" target={"_blank"} rel={"noreferrer"}>Zahnarzt</a>
          <a href="https://www.muenchen.de/service/branchenbuch/K/424.html" target={"_blank"} rel={"noreferrer"}>Kieferorthopäde</a>
          <a href="https://www.muenchen.de/service/branchenbuch/A/410.html" target={"_blank"} rel={"noreferrer"}>Allgemeinmedizin</a>
          <a href="https://www.muenchen.de/service/branchenbuch/O/930.html" target={"_blank"} rel={"noreferrer"}>Osteopathie</a>
          <a href="https://www.muenchen.de/service/branchenbuch/A/431.html" target={"_blank"} rel={"noreferrer"}>Orthopäden</a>
          <a href="https://www.muenchen.de/service/branchenbuch/A/419.html" target={"_blank"} rel={"noreferrer"}>HNO-Ärzte</a>
          <a href="https://www.muenchen.de/service/branchenbuch/P/858.html" target={"_blank"} rel={"noreferrer"}>Psychotherapie</a>
          <a href="https://www.muenchen.de/service/branchenbuch/A/413.html" target={"_blank"} rel={"noreferrer"}>Augenärzte</a>
          <a href="https://www.muenchen.de/service/branchenbuch/P/843.html" target={"_blank"} rel={"noreferrer"}>Pflegedienste</a>
          <a href="https://www.muenchen.de/service/branchenbuch/P/803.html" target={"_blank"} rel={"noreferrer"}>Physiotherapie</a>
          <a href="https://www.muenchen.de/service/branchenbuch/S/682.html" target={"_blank"} rel={"noreferrer"}>Sanitätshäuser</a>
          <a href="https://www.muenchen.de/service/branchenbuch/A/15.html" target={"_blank"} rel={"noreferrer"}>Apotheken</a>
          <a href="https://www.muenchen.de/service/branchenbuch/A/436.html" target={"_blank"} rel={"noreferrer"}>Radiologen</a>
          <a href="https://www.muenchen.de/service/branchenbuch/A/422.html" target={"_blank"} rel={"noreferrer"}>Internisten</a>
      </div>
      <section className="logos-without-title wf-section">
        <div className="container"></div>
        <a href="https://www.muenchen.de/service/branchenbuch/impressum.html" target={"_blank"} rel={"noreferrer"}className="link">Impressum</a></section>
    </div>
  );
}

export default App;
